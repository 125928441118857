import React from "react"

import styled from "styled-components"

import MainContainer from "../../atoms/mainContainer"
import Jumbotron from "react-bootstrap/Jumbotron"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import CardQuestion from "../../molecules/cardQuestion"

const Hero = styled(Jumbotron)`
  margin-bottom: 0px;
  border-radius: 0px;
  text-align: center;
`

const allQuestions = [
  {
    question: `What is the minimum investment size?`,
    answer: `
      The minimum investment threshold for Spere Financial notes is $100,000. If you
      would like to begin with a lower amount and build to the minimum $100,000 level,
      please contact us at <a href="/contact">contact form</a> or email us at
      <a href="mailto:info@sperefinancial.com">info@sperefinancial.com</a> to discuss the options.
    `,
  },
  {
    question: `When will I receive my payments?`,
    answer: `
      Spere Financial transfers payments directly into
      note investors’ accounts at the end of each
      month. Investors also receive a detailed schedule
      of payments that will be paid over the life of the
      note.
    `,
  },
  {
    question: `What are your fees?`,
    answer: `
      There are no fees for participating in the Spere
      Financial note program.
    `,
  },
  {
    question: `How do I make my first investment?`,
    answer: `
      Investments in Spere Financial notes are made by verifying accredited investor
      status, then reviewing and executing the offering prospectus. Once these steps
      are complete, the investor receives payment instructions for transferring funds to
      Spere Financial. <a href="/contact">Contact us</a> today to start earning higher
      interest on your capital.
    `,
  },
  {
    question: `Can I use retirement funds to invest in Spere Financial notes?`,
    answer: `
      Yes! You can use retirement funds in self-directed IRAs and earn interest
      tax-deferred or tax-free. <br/> We recommend Rocket Dollar, provider of
      self-directed Traditional IRAs, Roth IRAs, and Solo 401(k)s. <br/><br/>
      (Use the code <b>SPERE</b> for <i>$50 off</i> right before checkout).
    `,
  },
];

const HeroSection = () => {

  return (
    <>
      <Hero>
        <MainContainer>
          <Container>
            <Row>
              <Col xs="12">
                <h4 className="text-left mb-3">Questions?</h4>
              </Col>
              {allQuestions.map((member, index) => (
                <Col key={index} xs="12">
                  <CardQuestion question={member.question} answer={member.answer} />
                </Col>
              ))}
            </Row>
          </Container>
        </MainContainer>
      </Hero>
    </>
  )
}

export default HeroSection
